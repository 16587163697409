<template>
  <div v-if="selectedViewName=='home'" class="home">
    <b-button v-b-modal.modal-messagePopup-closing v-if="clickedQuestion!=''" class="messageText d-none d-lg-block" style="color:white!important;line-height: 1.5rem;">
    Contact us
    </b-button>
    <b-button v-if="clickedQuestion!=''" v-b-modal.modal-messagePopup-closing variant="primary" animation="fade" class="messagePopup d-none d-lg-block" style="color:white!important;">
      <b-icon icon="chat-right-text" font-scale="2" animation="fade"></b-icon>
    </b-button>
    <div v-if="clickedQuestion!=''" id="topNavBar" class="row" :class="classObject" style="border-bottom: 1px solid #1e4780;">
       <app-NavBar ref="navBar" :user="currentUser" @changeQuestion="changeQuestion($event)" />
       <!-- <b-modal id="login-Modal" size="md" centered hide-footer hide-header>
         <b-container >
            <b-row class="mb-1 text-center">
              <app-Login :parentPresent="popupPage"/>
              
            </b-row>
         </b-container>
       </b-modal> -->
       </div>
       
       <div :class="{'mb-3':clickedQuestion!='' & clickedQuestion!='coaching'}" >
         <!-- <hr v-if="clickedQuestion!=''"/> -->
         <!-- Speaking -->
  
         <app-ReadAloud v-if="clickedQuestion=='readAloud'"/>
         <app-AnswerShortQuestion v-if="clickedQuestion=='answerShortQuestion'"/>
         <app-DescibeImage v-if="clickedQuestion=='describeImage' | clickedQuestion=='describeimage'"/>
         <app-RepeatSentence v-if="clickedQuestion=='repeateSentence' | clickedQuestion=='repeatsentence'"/>
         <app-RetellLecture v-if="clickedQuestion=='retellLecture'"/>
         <!-- Writting -->
         <app-Summarized v-if="clickedQuestion=='summarized'"/>
         <app-Essay v-if="clickedQuestion=='essay'"/>
         <!-- Reading -->
        <app-MultipleSingle v-if="clickedQuestion=='multiplesingle' | clickedQuestion=='mcqSingle'"/>
        <app-MultipleDouble v-if="clickedQuestion=='multipledouble' | clickedQuestion=='mcqMultiple'"/>
        <app-ReOrder v-if="clickedQuestion=='reorder' | clickedQuestion=='reOrderPara'"/>
        <app-ReadingFill v-if="clickedQuestion=='fillread' | clickedQuestion=='readFill'"/>
        <app-ReadingWriteFill v-if="clickedQuestion=='fillreadwrite' | clickedQuestion=='readWriteFill'"/>
        <!-- Listening -->
        <app-SummarizedSpoken v-if="clickedQuestion=='summarizedspoken' | clickedQuestion=='summarizedSpoken'"/>
        <app-MultipleChoiceDouble v-if="clickedQuestion=='multiplechoicedouble' | clickedQuestion=='listMcqMultiple'"/>
        <app-MultipleChoiceSingle v-if="clickedQuestion=='multiplechoicesingle' | clickedQuestion=='listMcqSingle'"/>
        <app-HighLightCorrectSummary v-if="clickedQuestion=='highlightcorrectsummary' | clickedQuestion=='highLightSummary'"/>
        <app-SelectMissingWord v-if="clickedQuestion=='missingword' | clickedQuestion=='selectMissingWords'"/>
        <app-FillBlanks v-if="clickedQuestion=='fillblanks' | clickedQuestion=='listFillBlanks'"/>
        <app-HighLightCorrectWord v-if="clickedQuestion=='highligtcorrectword' | clickedQuestion=='highLightWords'"/>
        <app-WrittenDictation v-if="clickedQuestion=='writedictation' | clickedQuestion=='writeFromDict'"/>
        <!-- Listening -->
        <app-MockTest v-if="clickedQuestion=='mocktest'"/>
        <app-CreateMock v-if="clickedQuestion=='createMock'"/>
        <app-speaking v-if="clickedQuestion=='speaking'"/>
        <app-writing v-if="clickedQuestion=='writing'"/>
        <app-reading v-if="clickedQuestion=='reading'"/>
        <app-listening v-if="clickedQuestion=='listening'"/>
        <!-- User Profile -->
        <app-UserProfile v-if="clickedQuestion=='userProfile'"/>

        <!-- Free Mock Test -->
        <!-- <app-MockTestTemplate :mockId="freeMockTest" v-if="clickedQuestion=='freeMocktest' & freeMockTest != 0"/> -->
      <!-- <hr v-if="clickedQuestion!=''"/> -->
      <!-- DashBoard View -->
      <DashBoard ref="dashBoardView" :selectMenu="selectDashBoardMenu" v-if="clickedQuestion=='dashboard'"/>

        <!--  Home Main Slider -->
        <LoginHome v-if="clickedQuestion==''" :parentPresent="popupPage"/>
        <!-- <HomeSlider v-if="clickedQuestion==''"/> -->
        <!--  Prediction section counts under the home slider-->
          <!-- <PredictionSection v-if="clickedQuestion==''"/> -->
        <!--  PlatFormSection -->
          <!-- <PlatformSection v-if="clickedQuestion==''"/> -->
        <!--  Study Tools Component-->
          <!-- <StudyToolsComponent v-if="clickedQuestion==''"/> -->
        <!--  Download Section Component-->
        <!-- <Download-section v-if="clickedQuestion==''"/> -->
        <!--  download material section-->
          <!-- <DownloadMaterialSection v-if="clickedQuestion==''"/> -->
        <!--  PTE Knowledge Section-->
          <!-- <KnowledgeSection v-if="clickedQuestion==''"/> -->
        <!--  Testimonial Section-->
          <!-- <TestimonialSection v-if="clickedQuestion==''"/> -->
        <!--  Price Section-->
          <!-- <PriceSection id="PriceSection" :UpdatePlanParent="currentPlan" v-if="clickedQuestion==''"/> -->
        <!--  Download App Section-->
          <!-- <DownloadAppSection id="AppSection" v-if="clickedQuestion==''"/> -->

          <!-- <Coaching class="coaching"  v-if="clickedQuestion=='coaching'"/> -->
       </div>
       
      <audio id="beepAudio" src="@/assets/staticAudio/beep-07a.mp3" autostart="false" ></audio>
      <audio id="readAloudAudio" src="" autostart="false" ></audio>
  <b-modal
  id="modal-messagePopup-closing"
      ref="modal-messagePopup" hide-footer
      size="lg" centered
      title="Drop Us a Message">
    <form @submit.prevent="saveUserMessageToDB">
                      <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" v-model="userMessage.first_name" name="txtfName" class="form-control" placeholder="Your First Name *" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="userMessage.last_name" name="txtlName" class="form-control" placeholder="Your Last Name" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="userMessage.email" name="txtEmail" class="form-control" placeholder="Your Email *" value="" />
                            </div>
                            <div class="form-group">
                                <input type="text" v-model="userMessage.mobileNumber" name="txtMobileNumber" class="form-control" placeholder="Your Mobile Number *" value="" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea v-model="userMessage.message" maxlength="500" rows="5" name="txtMsg" class="form-control" placeholder="Your Message *" style="width: 100%; height: 150px!important;"></textarea>
                            </div>
                        </div>
                        <div class="col">
                          <div class="form-group pull-right">
                              <input type="submit" name="btnSubmit" class="btn btn-success" value="Send Message" />
                          </div>
                        </div>
                      </div>
                    </form>
  </b-modal>
    

   
  </div>
  <div v-else>
    <MockTestView/>
  </div>
  
</template>

<script>
// import MockTestView from './MockTestView.vue'
// import NavBar from '../components/navBar';
import LoginHome from '@/components/mainViews/loginHome'
// import DashBoard from '@/components/admin/Dashboard.vue'

// speaking
// import ReadAloud from '../components/speaking/readaloud'
// import AnswerShortQuestion from '../components/speaking/answershortquestion'
// import DescibeImage from '../components/speaking/describeimage'
// import RepeatSentence from '../components/speaking/repeatsentence'
// import RetellLecture from '../components/speaking/retelllecture'
// Writting
// import Summarized from '../components/writing/summarized'
// import Essay from '../components/writing/essay'
// reading
// import MultipleSingle from '../components/reading/multiplesingle'
// import MultipleDouble from '../components/reading/multipledouble'
// import ReOrder from '../components/reading/reorder'
// import ReadingFill from '../components/reading/readingfill'
// import ReadingWriteFill from '../components/reading/readingwrittingfill'
// listening
// import MultipleChoiceSingle from '../components/listening/multiplechoicesingle'
// import MultipleChoiceDouble from '../components/listening/multiplechiocedouble'
// import FillBlanks from '../components/listening/fillblanks'
// import HighLightCorrectSummary from '../components/listening/highlightcorrectsummary'
// import HighLightCorrectWord from '../components/listening/highlightincorrectword'
// import SelectMissingWord from '../components/listening/selectmissingword'
// import SummarizedSpoken from '../components/listening/summarizedspoken'
// import WrittenDictation from '../components/listening/writtendictation'

// import MockTest from '../components/mocktest/mocktest'
// import CreateMock from '../components/mocktest/mocktest_tab'
// import Speaking from '../components/createquestion/speaking/speaking'
// import Writing from '../components/createquestion/writing/writing'
// import Reading from '../components/createquestion/reading/reading'
// import Listening from '../components/createquestion/listening/listening'
// import UserProfile from '../components/user/profile'


import { mapGetters, mapActions } from 'vuex'
import unirest from "unirest"
import {bus} from '../main'
// import $ from 'jquery'
//import LocalStorageService from '../store/LocalStorageService'
export default {
  data(){
    return{
      
      currentUser:null,
      clickedQuestion:'',
      isCoaching:'No',
      sessionId:null,
      currentPlan:'Free',
      popupPage:'login',
      selectDashBoardMenu:'dashboard',
      moduleName:'', 
      show:false,
      selectedViewName:'home',
      userMessage:{
        user_id:0,
        first_name:'',
        last_name:'',
        email:'',
        mobileNumber:'',
        message:'',
        url:'users/createUserMessage'
      },
    }
  },
  computed:{
    classObject(){
      return (this.clickedQuestion!='' & this.clickedQuestion!='coaching')?'mb-3':'mb-0'
    }
  },
  components: {
    MockTestView: () => import('./MockTestView.vue'/* webpackChunkName: "MockTestView"*/),
    DashBoard: () => import('@/components/admin/Dashboard.vue'/* webpackChunkName: "DashBoard"*/),
    // Coaching,
    LoginHome,
    'app-NavBar': () => import('../components/navBar'/* webpackChunkName: "NavBar"*/),
    'app-ReadAloud': () => import('../components/speaking/readaloud'/* webpackChunkName: "ReadAloud"*/) ,
    'app-AnswerShortQuestion': () => import('../components/speaking/answershortquestion'/* webpackChunkName: "AnswerShortQuestion"*/),
    'app-DescibeImage': () => import('../components/speaking/describeimage'/* webpackChunkName: "DescibeImage"*/),
    'app-RepeatSentence': () => import('../components/speaking/repeatsentence'/* webpackChunkName: "RepeatSentence"*/),
    'app-RetellLecture': () => import('../components/speaking/retelllecture'/* webpackChunkName: "RetellLecture"*/),
    
    'app-Summarized': () => import('../components/writing/summarized'/* webpackChunkName: "Summarized"*/),
    'app-Essay': () => import('../components/writing/essay'/* webpackChunkName: "Essay"*/),

    'app-MultipleSingle': () => import('../components/reading/multiplesingle'/* webpackChunkName: "MultipleSingle"*/),
    'app-MultipleDouble': () => import('../components/reading/multipledouble'/* webpackChunkName: "MultipleDouble"*/),
    'app-ReOrder': () => import('../components/reading/reorder'/* webpackChunkName: "ReOrder"*/),
    'app-ReadingFill': () => import('../components/reading/readingfill'/* webpackChunkName: "ReadingFill"*/),
    'app-ReadingWriteFill': () => import('../components/reading/readingwrittingfill'/* webpackChunkName: "ReadingWriteFill"*/),
    
    'app-MultipleChoiceSingle': () => import('../components/listening/multiplechoicesingle'/* webpackChunkName: "MultipleChoiceSingle"*/),
    'app-MultipleChoiceDouble': () => import('../components/listening/multiplechiocedouble'/* webpackChunkName: "MultipleChoiceDouble"*/),
    'app-FillBlanks': () => import('../components/listening/fillblanks'/* webpackChunkName: "FillBlanks"*/),
    'app-HighLightCorrectSummary': () => import('../components/listening/highlightcorrectsummary'/* webpackChunkName: "HighLightCorrectSummary"*/),
    'app-HighLightCorrectWord': () => import('../components/listening/highlightincorrectword'/* webpackChunkName: "HighLightCorrectWord"*/),
    'app-SelectMissingWord': () => import('../components/listening/selectmissingword'/* webpackChunkName: "SelectMissingWord"*/),
    'app-SummarizedSpoken': () => import('../components/listening/summarizedspoken'/* webpackChunkName: "SummarizedSpoken"*/),
    'app-WrittenDictation': () => import('../components/listening/writtendictation'/* webpackChunkName: "WrittenDictation"*/),
    
    'app-MockTest': () => import('../components/mocktest/mocktest'/* webpackChunkName: "MockTest"*/),
    'app-CreateMock': () => import('../components/mocktest/mocktest_tab'/* webpackChunkName: "CreateMock"*/),

    'app-speaking': () => import('../components/createquestion/speaking/speaking'/* webpackChunkName: "Speaking"*/),
    'app-writing': () => import('../components/createquestion/writing/writing'/* webpackChunkName: "Writing"*/),
    'app-reading': () => import('../components/createquestion/reading/reading'/* webpackChunkName: "Reading"*/),
    'app-listening': () => import('../components/createquestion/listening/listening'/* webpackChunkName: "Listening"*/),
    'app-UserProfile': () => import('../components/user/profile'/* webpackChunkName: "UserProfile"*/),
    
  },
  methods:{
    ...mapActions({
        getUserById: 'auth/getUserById',
        questionservice: 'mocktest/fetchQuestion',
        updateUserRecord: 'auth/updateUserRecord',
        updateUser: 'auth/updateUserType',
        paypalQuery:'paypal/postPayPalQuery',
        reFetchUser:'auth/fetchUser',
        updateUserStripe: 'auth/updateUserType',
        saveData: 'speakmodule/saveAnswer',
         saveUserMessage:'auth/saveUserPersonalInfo'
      }),
      ...mapGetters({
          authDetail: 'auth/authenticated',
          getUserData:'auth/user',
          getQuestionList: 'mocktest/questions',
          getUserMessage: 'auth/UserMessages',
          paypalResponse:'paypal/getPayPalResponse',
          updateResponse: 'auth/UpdateResponse',
          getUser:'auth/user',
          dataResponse: 'speakmodule/AnswerResponse',
          getUserMessages: 'auth/getPersonalInfo'
      }),
      
      saveUserMessageToDB(){
      if(this.userMessage.first_name.trim()==''| this.userMessage.message.trim()==''|this.userMessage.email.trim()==''|this.userMessage.mobileNumber.trim()==''){
        return this.$alert('Please fill all the required fields');
      }
      else{
        this.$alert('You message received, our Team will contact you shortly.')
        var currentUser=this.$ls.get('user')
        this.userMessage.user_id=currentUser.user_id
        this.saveUserMessage(this.userMessage).then(()=>{
          this.sendUserMessage(this.userMessage.mobileNumber,this.userMessage.firstName,this.userMessage.userId);
            let melbOffice="+61435600610";
          this.sendMessageOffice(melbOffice,this.userMessage.firstName,this.userMessage.userId,this.userMessage.message);
          let pakOffice="+923303153327";
          this.sendMessageOffice(pakOffice,this.userMessage.firstName,this.userMessage.userId,this.userMessage.message);
          this.userMessage.message=''
        })
      }
    },
    sendUserMessage(mobileNumber,firstName,userId){
      var req = unirest('POST', 'https://emailservice.cyberleaf-solutions.com/api/clickSend/sendMessageByCallingApi')
                .headers({
                  'Content-Type': 'application/json'
                })
                .send(JSON.stringify({
                  "Client_Id": userId,
                  "Client_Name": firstName,
                  "toNumber": mobileNumber,
                  "messageBody": "Thank You! We have received your query and our concerned department will get back to you shortly",
                  "Purpose": "PTE User Query response",
                  "Delivery_Report": "Send Click not called yet.",
                  "DB_Saved_Status": "Not Saved yet."
                }))
                .end(function (res) { 
                  if (res.error) throw new Error(res.error); 
                  //console.log(res.raw_body);
                });
    },
    sendMessageOffice(mobileNumber,firstName,userId,msg){
      var req = unirest('POST', 'https://emailservice.cyberleaf-solutions.com/api/clickSend/sendMessageByCallingApi')
                .headers({
                  'Content-Type': 'application/json'
                })
                .send(JSON.stringify({
                  "Client_Id": userId,
                  "Client_Name": firstName,
                  "toNumber": mobileNumber,
                  "messageBody": msg,
                  "Purpose": "PTE Query to Office",
                  "Delivery_Report": "Send Click not called yet.",
                  "DB_Saved_Status": "Not Saved yet."
                }))
                .end(function (res) { 
                  if (res.error) throw new Error(res.error); 
                  //console.log(res.raw_body);
                });
    },
      PracticeNow(){
        this.$refs.navBar.PracticeNow()
      },
      StripeCancelPayment(){
        const email=this.$ls.get('user').email_id
          var userData={
              email_id:email
              }
          this.reFetchUser(userData)
          this.$ls.set('cancel_Payment','cancel')
          //this.$router.push('/')
          this.$ls.set('redirect','CancelPayment')
          this.$ls.set('paypalReturn','')
          this.$ls.get('packageAmount','')
          this.$router.replace({'query': null});
          this.checkRedirect()
          //window.location.reload()
      },
      addDays(days) {
        var date =(this.$ls.get('ProductExpiryDate')==null | this.$ls.get('ProductExpiryDate')=='')? new Date():this.$ls.get('ProductExpiryDate');
        var result = new Date(date);
        result.setDate(date.getDate() + days);
        return result.getFullYear() +'-'+(result.getMonth() + 1) + '-' + result.getDate() + ' 23:59:00' ;
        //return result;
      },
      StripeSuccessPayment(){
         var vm=this
        var currentUser=vm.$ls.get('user')
        var sessionId = this.$route.query.successPayment;
        this.$router.replace({'query': null});
        //console.log(sessionId)
        return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/checkout-session", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body:JSON.stringify({
            sessionId: sessionId
          })
        }).then(function(result) {
             vm.$ls.set('sessionId',sessionId)
             vm.$ls.set('redirect','SucessPayment')
           // vm.$router.push('/')
           vm.checkRedirect()
            //window.location.reload()
            //console.log(sessionId)
            let userType='premium'
            let productPackageName=(vm.$ls.get('PackageType')!='premium')?vm.$ls.get('PackageType'):null;
            let productExpiry=null;
            if(productPackageName=='7-Days') {
              productExpiry=vm.addDays(7);
            }
            else if(productPackageName=='15-Days') {
              productExpiry=vm.addDays(15);
            }
            else if(productPackageName=='30-Days') {
              productExpiry=vm.addDays(30);
            }
            else if(productPackageName=='90-Days') {
              productExpiry=vm.addDays(90);
            }
            let productUser=(productExpiry!=null)?true:false; 
            result.json().then((values)=>{
                //console.log(values)
                var data={
                    pte_payment_type:'Stripe',
                    customer_id:values.customer,
                    amount:values.amount_total,
                    email:currentUser.email_id,
                    userName:currentUser.first_name,
                    stripe_email:values.customer_details.email,
                    url:'users/updateStripeValues',
                    user_id: currentUser.user_id,
                    user_type:userType, //vm.$ls.get('PackageType'),
                    stripe_SessionId:sessionId,
                    stripe_subscription:values.subscription,
                    payment_date:null,
                    strip_membership:JSON.stringify(values),
                    Product_User:productUser,
                    Product_Expiry:productExpiry,
                    Product_Package_Name:productPackageName
                }
                var referralUserStatus={
                    register_user_status:userType,//vm.$ls.get('PackageType'),
                    register_user_id:currentUser.user_id,
                    url:'users/updateReferralRegUserStatus',
                  }
                var vvm =vm
                vm.updateUserStripe(data).then(()=>{
                  ////debugger
                  // var response = vvm.updateResponse()
                  // //console.log(response)
                  // if(response.success==1){
                    currentUser.user_type = userType //vvm.$ls.get('PackageType')
                    currentUser.stripe_customerId=data.customer_id
                    var start= new Date();
                    var end= new Date(start.getFullYear(), start.getMonth()+1, 1);
                    var days = (end- start) / (1000 * 60 * 60 * 24);
                    vm.$ls.set('remainingDays',days)
                    vvm.$ls.set('user',currentUser)
                    var userData={
                      email_id:currentUser.email_id
                    }
                    if(referralUserStatus!=null){
                      vvm.saveData(referralUserStatus)
                    }
                    vvm.reFetchUser(userData).then(()=>{
                     // vvm.$ls.set('user',vvm.getUser())
                    })
                })

            })

        });
      },
      StripeCoachingSuccessPayment(){
        var vm=this
        var currentUser=vm.$ls.get('user')
        var sessionId = this.$route.query.PaymentSuccessCoaching;
         this.$router.replace({'query': null});
        //console.log(sessionId)
        return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/checkout-session", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body:JSON.stringify({
            sessionId: sessionId
          })
        }).then(function(result) {
             //vm.$ls.set('sessionId',sessionId)
             vm.$ls.set('redirect','CoachingSucessPayment')
            //vm.$router.push('/')
            vm.checkRedirect()
            //window.location.reload()
            //console.log(sessionId)
            result.json().then((values)=>{
                //console.log(values)
                var data={
                    coaching_payment_type:'Stripe',
                    coaching_customer_id:values.customer,
                    coaching_amount:values.amount_total,
                    coaching_email:currentUser.email_id,
                    userName:currentUser.first_name,
                    coaching_stripe_email:values.customer_details.email,
                    url:'users/updateStripeCoachingValues',
                    user_id: currentUser.user_id,
                    email:currentUser.email_id,
                    coaching:vm.$ls.get('CoachingPackageType'),
                    coaching_stripe_SessionId:sessionId,
                    coaching_stripe_subscription:values.subscription,
                    coaching_payment_date:null,
                    coaching_stripe_membership:JSON.stringify(values)
                }
                var vvm =vm
                vm.updateUserStripe(data).then(()=>{
                  // if(response.success==1){
                    var userData={
                      email_id:currentUser.email_id
                    }
                    vvm.reFetchUser(userData).then(()=>{
                      ////debugger
                      //console.log(vvm.getUser())
                      //vvm.$ls.set('user',vvm.getUser())
                    }) 
                })
            })
        });
      },
      PayPalPaymentSuccess(){
        var vm=this
        var currentUser=vm.$ls.get('user')
        let userType='premium'
            let productPackageName=(vm.$ls.get('PackageType')!='premium')?vm.$ls.get('PackageType'):null;
            let productExpiry=null;
            if(productPackageName=='7-Days') {
              productExpiry=vm.addDays(7);
            }
            else if(productPackageName=='15-Days') {
              productExpiry=vm.addDays(15);
            }
            else if(productPackageName=='30-Days') {
              productExpiry=vm.addDays(30);
            }
            else if(productPackageName=='90-Days') {
              productExpiry=vm.addDays(90);
            }
            let productUser=(productExpiry!=null)?true:false; 
        //var paypalReturn=vm.$ls.get('paypalReturn')
        var packageAmount=vm.$ls.get('packageAmount')
        //https://pteclasses.com/PayPalPaymentSuccess:planType=standard?subscription_id=I-NABYA6LYRMSM&ba_token=BA-1RL88255A23114434&token=96D88510U5898311X
        var planType = userType;//this.$route.query.PayPalPaymentSuccess;
        var plan=this.$route.fullPath
        var id=this.getParameterByName('subscription_id',this.$route.fullPath);
        //console.log(id)
        //id=id.replace('?subscription_id','')
        var data=null;
        var referralUserStatus=null
         this.$router.replace({'query': null});
        if(planType=='standard' | planType=='premium')
        {
          referralUserStatus={
           register_user_status:planType,
           register_user_id:currentUser.user_id,
           url:'users/updateReferralRegUserStatus',
         }
          vm.$ls.set('redirect','SucessPayment')
            //vm.$router.push('/')
            vm.checkRedirect()
            //window.location.reload()
          data={
                  pte_payment_type:'PayPal',
                  customer_id:id,
                  amount:packageAmount,
                  email:currentUser.email_id,
                  userName:currentUser.first_name,
                  stripe_email:currentUser.email_id,
                  url:'users/updateStripeValues',
                  user_id: currentUser.user_id,
                  user_type:planType,
                  stripe_SessionId:null,
                  stripe_subscription:null,
                  payment_date:null,
                  strip_membership:JSON.stringify(plan),
                  Product_User:productUser,
                  Product_Expiry:productExpiry,
                  Product_Package_Name:productPackageName
                }
        }
        else{
          vm.$ls.set('redirect','CoachingSucessPayment')
          //vm.$router.push('/')
          vm.checkRedirect()
          //window.location.reload()
          data={
                    coaching_payment_type:'PayPal',
                    coaching_customer_id:id,
                    coaching_amount:packageAmount,
                    coaching_email:currentUser.email_id,
                    userName:currentUser.first_name,
                    coaching_stripe_email:currentUser.email_id,
                    url:'users/updateStripeCoachingValues',
                    user_id: currentUser.user_id,
                    email:currentUser.email_id,
                    coaching:planType,
                    coaching_stripe_SessionId:null,
                    coaching_stripe_subscription:null,
                    coaching_payment_date:null,
                    coaching_stripe_membership:JSON.stringify(plan)
                }
        }
         
                var vvm =vm
                vm.updateUserStripe(data).then(()=>{
                    var userData={
                      email_id:currentUser.email_id
                    }
                    if(referralUserStatus!=null){
                      vvm.saveData(referralUserStatus)
                    }
                    vvm.reFetchUser(userData)
                  //}
                })

      },
      getParameterByName(name, url)
      {
          name=name.replace(/[\\[\\]]/g,'\\$&');
          var regex = new RegExp('[?&]'+ name +'(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
          if (!results) return null;
          if (!results[2]) return '';
          return decodeURIComponent(results[2].replace(/\+/g, ' '));
      },
      changeQuestion(currentQuestion){
        debugger
        if(this.$route.query.viewType){
          this.selectedViewName=this.$route.query.viewType
           this.$router.replace({'query': null});
        }
        else if(this.$route.query.cancel_payment){
          this.StripeCancelPayment()
        }
        else if(this.$route.query.successPayment)
        {
          this.StripeSuccessPayment()
        }
        else if(this.$route.query.PaymentSuccessCoaching){
          this.StripeCoachingSuccessPayment()
        }
        else if(this.$route.query.PayPalPaymentSuccess){
          this.PayPalPaymentSuccess()
          
        }
        else if(this.$route.query.state){
          debugger
          console.log(this.$route.query.state)
        }
        // this.$nextTick(()=>{
        //   this.checkRedirect()
        // })
        if(this.clickedQuestion==currentQuestion){
          if(this.clickedQuestion=='dashboard')
          {
            this.$refs.dashBoardView.changeMenuItem(this.selectDashBoardMenu)
          }
        }
        if(currentQuestion=='freeMocktest'){
          let routeData = this.$router.resolve({name: 'mockTest'});
          window.open(routeData.href, '_blank');
        }
        else if(currentQuestion ==''){
          
          if(this.$ls.get('user')!=null){
            this.currentQuestion='dashboard'
            this.clickedQuestion = currentQuestion
            this.$ls.set('currentQuestion',currentQuestion)
          }
          else{
            this.clickedQuestion = currentQuestion
            this.$ls.set('currentQuestion',currentQuestion)
          }
        }
        else{
            this.clickedQuestion = currentQuestion
            this.$ls.set('currentQuestion',currentQuestion)
        }
      },
    
      checkPTEExpiry(expiry){
        
      var checkExpiry=true;
      if(expiry!=null & expiry!=''){
        var expiryDate = new Date(expiry);
        var currentDate=new Date();
        checkExpiry = (expiryDate>=currentDate)? true: false;
      }
      return checkExpiry
    },
    checkPTEProductExpiry(expiry){
        
        var checkExpiry=true;
        if(expiry!=null & expiry!=''){
          console.log(expiry);
          var expiryDate = new Date(expiry);
          console.log(expiryDate);
          var currentDate=new Date();
          console.log(currentDate);
          checkExpiry = (expiryDate>currentDate)? true: false;
        }
        return checkExpiry
      },
    
      recheckUser(){
        debugger
        var loginUser=this.$ls.get('user')
        var vm=this
        //if(loginUser.stripe_customerId==null) return this.resetUser()
        if(loginUser!=null)
        if(loginUser.is_admin!='T')
        {
          
          if(loginUser.Product_User){
            if(!vm.checkPTEProductExpiry(loginUser.Product_Expiry)){
              console.log("Update user in Product Section condition.")
              vm.updateFreeUser(loginUser);
            }
            else{
              vm.resetUser();
            }
          }
          // to check if pte expiry is manually added in the db
          else if(loginUser.pte_payment_type !=null & loginUser.pte_payment_type=='Stripe')
          {
            if(loginUser.stripe_customerId==null) return this.resetUser()
            return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/subscriptions",{
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  customerId: loginUser.stripe_customerId
                })
                }).then((results)=>{
                    results.json().then((res)=>{
                      //console.log(res)
                      if(res.subscriptions.data.length>0)
                      {
                        var resData=res.subscriptions.data[0]
                        if(resData.status!="active" & loginUser.user_type!='free')
                        {
                          loginUser.user_type = vm.$parent.currentPlan= 'Free'
                          vm.$ls.set('PackageType','Free')
                          vm.$ls.set('user',loginUser)
                          var data={
                              user_id: loginUser.user_id,
                              user_type:'Free',
                              url:'users/userupdatetype',
                              payment_cancel:null,
                              cancel_membership:JSON.stringify(resData)
                            }
                            vm.updateUser(data).then(()=>{
                              //console.log('No more paid member ship')
                              vm.resetUser()
                              //this.$ls.remove('PackageType')
                            })
                        }
                        else if(resData.status=="active"){
                          const plan=(resData.plan.id=== process.env.VUE_APP_PREMIUM_PRICE_ID)?'premium':'standard';
                          // const expiryDate=resData.current_period_end
                          vm.$ls.set('PackageType',plan)
                          loginUser.user_type = vm.$parent.currentPlan= vm.$ls.get('PackageType')
                          vm.$ls.set('user',loginUser)
                          vm.resetUser()
                          // vm.$ls.set('remainingDays',)
                          // vm.$ls.set('PackageType',plan)
                          //           var updatedata={
                          //       amount:resData.plan.amount,
                          //       url:'users/updateChangeStripePackage',
                          //       user_id: loginUser.user_id,
                          //       user_type:plan,
                          //       payment_date:loginUser.payment_date,
                          //       strip_membership:JSON.stringify(resData)
                          //   }
                          // ////console.log(data)
                          // vm.updateUser(updatedata).then(()=>{
                          //         loginUser.user_type = vm.$parent.currentPlan= vm.$ls.get('PackageType')
                          //         vm.$ls.set('user',loginUser)
                          //         vm.resetUser()
                          //       // window.location.reload()
                          //         ////console.log(response)
                          //     })
                      }
                      else 
                      {
                          loginUser.user_type = vm.$parent.currentPlan= 'Free'
                          vm.$ls.set('PackageType','Free')
                          vm.$ls.set('user',loginUser)
                          var regfreeData={
                              user_id: loginUser.user_id,
                              user_type:'Free',
                              url:'users/userupdatetype',
                              payment_cancel:null,
                              cancel_membership:JSON.stringify(resData)
                            }
                            vm.updateUser(regfreeData).then(()=>{
                              //console.log('No more paid member ship')
                              vm.resetUser()
                              //this.$ls.remove('PackageType')
                            })
                      }
                    }
                    else 
                    {
                        vm.updateFreeUser(loginUser)
                    }
                  })
                })
          }
          else if(loginUser.pte_payment_type !=null & loginUser.pte_payment_type=='PayPal'){
              var data={
                  url:'payPalPayment/get-token'
                }
                vm.paypalQuery(data).then(()=>{
                    let tokenResponse=vm.paypalResponse().response;
                    //console.log(tokenResponse)
                    if(tokenResponse.status==200){
                      var getSubdata={
                          url:'payPalPayment/get-subscription',
                          paypal_token:tokenResponse,
                          paypal_subscription_id:loginUser.stripe_customerId
                        }
                      vm.paypalQuery(getSubdata).then(()=>{
                        let getSubResponse=vm.paypalResponse();
                        debugger
                        console.log(getSubResponse);
                        //& getSubResponse.response.billing_info.failed_payments_count==0
                        if(getSubResponse.status==200 )
                        {
                          vm.$ls.set('user',loginUser)
                          vm.resetUser()
                        }
                        else{
                          loginUser.user_type = vm.$parent.currentPlan= 'Free'
                          vm.$ls.set('PackageType','Free')
                          vm.$ls.set('user',loginUser)
                          var regfreeData={
                              user_id: loginUser.user_id,
                              user_type:'Free',
                              url:'users/userupdatetype',
                              payment_cancel:"Due to paypal delinquent status",
                              cancel_membership:JSON.stringify(getSubResponse)
                            }
                            vm.updateUser(regfreeData).then(()=>{
                              //console.log('No more paid member ship')
                              vm.resetUser()
                              //this.$ls.remove('PackageType')
                            })
                        }
                      })
                    }
                });
              vm.resetUser()
              //console.log('Paypal User')
            }
          else if(loginUser.pteExpiry!=null & loginUser.pte_payment_type=='Cash'){
                if(!vm.checkPTEExpiry(loginUser.pteExpiry))
                {
                    loginUser.user_type = vm.$parent.currentPlan= 'Free'
                      vm.$ls.set('PackageType','Free')
                      vm.$ls.set('user',loginUser)
                      var userdata={
                          user_id: loginUser.user_id,
                          user_type:'Free',
                          url:'users/userupdatetype',
                          payment_cancel:null,
                          cancel_membership:null
                        }
                        vm.updateUser(userdata).then(()=>{
                          //console.log('No more paid member ship')
                          vm.resetUser()
                          //this.$ls.remove('PackageType')
                        })
                }
                else{
                  vm.resetUser()
                }
          }
          else{
            console.log("UPdate user in Else condition.")
              vm.updateFreeUser(loginUser)
            }
            this.recheckCoachingStripe()
        }
        else 
        {
            this.resetUser()
        }
        this.recheckModule()
       
      },
      recheckModule(){
        //console.log(this.moduleName)
          if(this.moduleName!=''){
            this.$ls.set('currentQuestion',this.moduleName)
            this.changeQuestion(this.moduleName)
            // //console.log(this.moduleName)
            // this.$refs.navBar.changeQuestion(this.moduleName)
            this.moduleName=''
            }
         
      },
      recheckCoachingStripe()
      {
        debugger;
        var loginUser=this.$ls.get('user')
        var vm=this
        if(loginUser.coaching_payment_type !=null & loginUser.coaching_payment_type=='Stripe'){
              return fetch(process.env.VUE_APP_BASE_URL+"/stripePayment/subscriptions",{
                method: "POST",
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify({
                  customerId: loginUser.coaching_stripe_customerId
                })
                }).then((results)=>{
                    results.json().then((res)=>{
                      //console.log(res)
                      if(res.subscriptions.data.length>0)
                      {
                        var resData=res.subscriptions.data[0]
                        if(resData.status!="active")
                        {
                          loginUser.coaching='No'
                          vm.$ls.set('user',loginUser)
                          var data={
                            coaching_payment_type:'No',
                            userName:loginUser.first_name,
                            email:loginUser.email_id,
                            user_id: loginUser.user_id,
                            coaching:'No',
                            url:'users/updateUserCoachingType',
                            coaching_payment_cancel:null,
                            coaching_cancel_membership:JSON.stringify(resData)
                            }
                            vm.updateUser(data).then(()=>{
                              //console.log('No more paid member ship')
                              vm.resetUser()
                              //this.$ls.remove('PackageType')
                            })
                        }
                        else if(resData.status=="active"){
                          const plan=(resData.plan.id=== process.env.VUE_APP_PREMIUM_PRICE_ID)?'premium':'standard';
                          // const expiryDate=resData.current_period_end
                          vm.$ls.set('PackageType',plan)
                          loginUser.user_type = vm.$parent.currentPlan= vm.$ls.get('PackageType')
                          vm.$ls.set('user',loginUser)
                          vm.resetUser()
                        }
                      }
                      else{
                          loginUser.coaching='No'
                          vm.$ls.set('user',loginUser)
                          var userdata={
                            coaching_payment_type:'No',
                            userName:loginUser.first_name,
                            email:loginUser.email_id,
                            user_id: loginUser.user_id,
                            coaching:'No',
                            url:'users/updateUserCoachingType',
                            coaching_payment_cancel:null,
                            coaching_cancel_membership:JSON.stringify(resData)
                            }
                            vm.updateUser(userdata).then(()=>{
                              //console.log('No more paid member ship')
                              vm.resetUser()
                              //this.$ls.remove('PackageType')
                            })
                      }
                  })
                })
            }
            else if(loginUser.coaching_payment_type !=null & loginUser.coaching_payment_type=='PayPal'){
              var data={
                  url:'payPalPayment/get-token'
                }
                vm.paypalQuery(data).then(()=>{
                    let tokenResponse=vm.paypalResponse();
                    //console.log(tokenResponse)
                    if(tokenResponse.status==200){
                      var getSubdata={
                          url:'payPalPayment/get-subscription',
                          paypal_subscription_id:vm.currentUser.coaching_stripe_customerId
                        }
                      vm.paypalQuery(getSubdata).then(()=>{
                        let getSubResponse=vm.paypalResponse()
                        if(getSubResponse.status==200)
                        {
                          vm.$ls.set('user',loginUser)
                          vm.resetUser()
                        }
                        else{
                          loginUser.coaching='No'
                          vm.$ls.set('user',loginUser)
                          var userdata={
                            coaching_payment_type:'No',
                            userName:loginUser.first_name,
                            email:loginUser.email_id,
                            user_id: loginUser.user_id,
                            coaching:'No',
                            url:'users/updateUserCoachingType',
                            coaching_payment_cancel:null,
                            coaching_cancel_membership:JSON.stringify(getSubResponse)
                            }
                            vm.updateUser(userdata).then(()=>{
                              //console.log('No more paid member ship')
                              vm.resetUser()
                              //this.$ls.remove('PackageType')
                            })
                        }
                      })
                    }
                });
            }
      },
      updateFreeUser(loginUser){
        var vm=this
        //console.log(loginUser)
        if(loginUser.user_type!='Free')
        {
           loginUser.user_type = vm.$parent.currentPlan= 'Free'
                        vm.$ls.set('PackageType','Free')
                        vm.$ls.set('user',loginUser)
                        var freeData={
                            user_id: loginUser.user_id,
                            user_type:'Free',
                            url:'users/userupdatetype',
                            payment_cancel:null,
                            cancel_membership:null
                          }
                          vm.updateUser(freeData).then(()=>{
                            //console.log('No more paid member ship')
                            vm.resetUser()
                            //this.$ls.remove('PackageType')
                          })
        }
        else
        {
          vm.resetUser()
        }
       
      },
      resetUser(){
        //this.$store.state.token = LocalStorageService.getAccessToken();
        this.$store.state.token = this.$ls.get('tokenVal')
        this.$store.state.uniqueKey=this.$ls.get('uniqueKey')
        if(this.$ls.get('user')=='[object Object]'){
          this.currentUser = this.$store.state.user =JSON.parse(this.$browserStore.getLocal('backUser'))
          this.$ls.set('user',this.currentUser)
        }
        else this.currentUser = this.$store.state.user =JSON.parse(this.$ls.get('user'))//=this.$browserStore.getLocal('user')
        ////console.log(this.currentUser)
        if(this.currentUser!=null)
        {
          if(this.currentUser.pteExpiry==null &this.currentUser.payment_date!=null){
            //var t = this.currentUser.payment_date.split(/[// :]/);
            //const paymentDay=t[0]
            // const paymentMonth=t[1]
            // //console.log(paymentMonth)
            // var currentDate=new Date();
            var days=0
            // //console.log(currentDate.getMonth())
            // var currentMonthDate=new Date(currentDate.getMonth()+'/'+paymentDay+'/'+ currentDate.getFullYear()+' 23:59:59');
            // var nextMonthDate=new Date(currentDate.getMonth()+1+'/'+paymentDay+'/'+ currentDate.getFullYear()+' 23:59:59');
            // //console.log(currentMonthDate)
            // //console.log(nextMonthDate)
            // days = ((nextMonthDate- currentMonthDate)) / (1000 * 60 * 60 * 24);
            // days=Math.round(days)
            this.$ls.set('remainingDays',days)
          }
        }
        
        if(this.$ls.get('user')!=null){
          //console.log('Inside dashboard condition')
          //this.currentQuestion = 'dashboard'
          //$('#homeButton').trigger('click')
          
          this.$nextTick(()=>{
            if(this.$ls.get('currentQuestion')=='dashboard')
              {
                this.changeQuestion(this.$ls.get('currentQuestion'))
              
                // if(this.$ls.get('currentQuestion')=='dashboard') 
                // {
                  //this.$refs.navBar.listClick('Home')
                  //this.$refs.navBar.changeQuestion('dashboard')
                  //window.location.reload()
                //}
              }
            //window.location.reload()
          })
        }
        
        //
        //LocalStorageService.getUser();
        ////console.log("Current User in HOme" + this.currentUser)
      },
      
      externalLogout(){
        this.$refs.navBar.signOut()
      },
      checkRedirect(){
        ////debugger
        if(this.$ls.get('redirect')!=null){
      const redirect=this.$ls.get('redirect')
      if(redirect == 'SucessPayment'){
        this.sessionId = this.$ls.get('sessionId')
        this.$alert(
          "Your New Plan is Updated, Start Practicing",
          "Success",
          "success",
          {
            confirmButtonText: "Congratulation, Let's Start!"
          }
        ).then(()=>{
          this.currentPlan='premium'//this.$ls.get('PackageType')
          window.location.reload()
          this.$ls.remove('sessionId')
          this.$ls.remove('PackageType')
        })
      }
      else if(redirect == 'CancelPayment'){
        this.$alert(
          "Payment process is cancelled",
          "Error",
          "error",
          {
            confirmButtonText: "Payment Cancel"
          }
        ).then(()=>{
//this.currentPlan='premium'
        //  window.location.reload()
          this.$ls.remove('cancel_Payment')
          this.$ls.remove('PackageType')
        })
      }
      else if(redirect == 'EmailVerification'){
        var data={
          active_ind:'T',
          user_id:this.$ls.get('userId'),
          url:'users/activateAccount'
        }
        var vm= this
        this.updateUserRecord(data).then(()=>{
          const message=vm.getUserMessage()
            vm.$alert(
            message,
            "Success",
            "success",
            {
              confirmButtonText: "Ready, Let's Start!"
            }
          ).then(()=>{
            vm.$ls.remove('tokenVal')
            vm.$ls.remove('uniqueKey')
            vm.$ls.remove('userId')
            vm.popupPage='login'
           // vm.$root.$emit('bv::show::modal', 'login-Modal')
          })
        })
        
      }
      else if(redirect == 'ResetPasswordRedirect'){
        this.popupPage='resetPassword'
        
      }
      else if(redirect == 'CoachingSucessPayment'){
        this.sessionId = this.$ls.get('sessionId')
        this.$alert(
          "Your New Coaching Plan is Updated, you can access coaching material",
          "Success",
          "success",
          {
            confirmButtonText: "Congratulation, Let's Start!"
          }
        ).then(()=>{
          window.location.reload()
          //this.currentPlan=this.$ls.get('PackageType')
          //this.$ls.remove('sessionId')
          //this.$ls.remove('PackageType')
        })
      }
      this.$ls.remove('redirect')
    }
      },
        PromptMicPermission(){
            let localStream = new MediaStream();
            let localAudioTrack;
            //let localVideoTrack;
            //let localVideo;

            const userMediaConstraints = {
            // video: true,
                audio: true
            };

            navigator.mediaDevices.getUserMedia(userMediaConstraints)
                .then(stream => { 
                    localAudioTrack = stream.getAudioTracks()[0];
                    localAudioTrack.enabled = true;
                    localStream.addTrack(localAudioTrack);

                // localVideoTrack = stream.getVideoTracks()[0];
                    //localVideoTrack.enabled = true;
                // localStream.addTrack(localVideoTrack);

                    //localVideo = document.createElement('video');
                    videoContainer.append(localVideo);
                // localVideo.setAttribute('id','localVideo');
                // localVideo.srcObject = localStream;
                // localVideo.muted = true;

                    //localVideo.play();
                });
        },
        checkMicPermission(){
            var vm=this;
            try{
            navigator.permissions.query(
                { name: 'microphone' }
            ).then(function(permissionStatus){
                //console.log("Current state: " + permissionStatus.state)
                if(permissionStatus.state=="prompt"|permissionStatus.state=="denied"){
                 
                    vm.PromptMicPermission();
                }
                if(permissionStatus.state=="denied"){
                  vm.$alert("Microphone access is required for speaking tasks.","Reminder","warning");
                }
                var vvm=vm;
                permissionStatus.onchange = function(){
                if (this.state == "granted") {
                    //console.log("Allow");
                } else if (this.state == "denied") {
                    vvm.PromptMicPermission();
                } else if (this.state == "prompt") {
                    vvm.PromptMicPermission();
                }
                }
            },()=>{
              vm.PromptMicPermission();
            }).catch(e => {
              vm.PromptMicPermission();
            });
          }
                  catch(error){
                    vm.$alert("Microphone access is required for speaking tasks.","Reminder","warning");
                  }
        },
        
  },
  created(){
    bus.$on('checkMicPermission', this.checkMicPermission);
    if(this.selectedViewName=='home'){ 
      debugger
    if(this.$ls.get('tokenVal') == null){
      if(this.$browserStore.getLocal('tokenVal')!=null & this.$browserStore.getLocal('backUser')!=null){
        this.$ls.set('tokenVal',this.$browserStore.getLocal('tokenVal'))
        this.$ls.set('uniqueKey',this.$browserStore.getLocal('uniqueKey'))
        this.$ls.set('currentQuestion','dashboard')
        this.$ls.set('user',JSON.parse(this.$browserStore.getLocal('backUser')))
      } 
    }
    if(this.$ls.get('tokenVal') != null){
      
      this.currentUser = this.getUserData();
      
      if(this.currentUser == null){
        this.resetUser()
      }
      else{
        this.isCoaching=(this.currentUser.coaching==null)?'No':this.currentUser.coaching;
      }
    if(this.$ls.get('currentQuestion')!=null)
    {
      this.changeQuestion(this.$ls.get('currentQuestion'))}
    }
   // this.checkRedirect()
    // if(this.$ls.get('redirect')!=null){
    //   const redirect=this.$ls.get('redirect')
    //   if(redirect == 'SucessPayment'){
    //     this.sessionId = this.$ls.get('sessionId')
    //     this.$alert(
    //       "Your New Plan is Updated, Start Practicing",
    //       "Success",
    //       "success",
    //       {
    //         confirmButtonText: "Congratulation, Let's Start!"
    //       }
    //     ).then(()=>{
    //       this.currentPlan=this.$ls.get('PackageType')
    //       window.location.reload()
    //       this.$ls.remove('sessionId')
    //       this.$ls.remove('PackageType')
    //     })
    //   }
    //   else if(redirect == 'CancelPayment'){
    //     this.$alert(
    //       "Payment process is cancelled",
    //       "Error",
    //       "error",
    //       {
    //         confirmButtonText: "Payment Cancel"
    //       }
    //     ).then(()=>{
    //       window.location.reload()
    //       this.$ls.remove('cancel_Payment')
    //       this.$ls.remove('PackageType')
    //     })
    //   }
    //   else if(redirect == 'EmailVerification'){
    //     var data={
    //       active_ind:'T',
    //       user_id:this.$ls.get('userId'),
    //       url:'users/activateAccount'
    //     }
    //     var vm= this
    //     this.updateUserRecord(data).then(()=>{
    //       const message=vm.getUserMessage()
    //         vm.$alert(
    //         message,
    //         "Success",
    //         "success",
    //         {
    //           confirmButtonText: "Ready, Let's Start!"
    //         }
    //       ).then(()=>{
    //         vm.$ls.remove('tokenVal')
    //         vm.$ls.remove('userId')
    //         vm.popupPage='login'
    //        // vm.$root.$emit('bv::show::modal', 'login-Modal')
    //       })
    //     })
        
    //   }
    //   else if(redirect == 'ResetPasswordRedirect'){
    //     this.popupPage='resetPassword'
        
    //   }
    //   else if(redirect == 'CoachingSucessPayment'){
    //     this.sessionId = this.$ls.get('sessionId')
    //     this.$alert(
    //       "Your New Coaching Plan is Updated, you can access coaching material",
    //       "Success",
    //       "success",
    //       {
    //         confirmButtonText: "Congratulation, Let's Start!"
    //       }
    //     ).then(()=>{
    //       window.location.reload()
    //       //this.currentPlan=this.$ls.get('PackageType')
    //       //this.$ls.remove('sessionId')
    //       //this.$ls.remove('PackageType')
    //     })
    //   }
    //   this.$ls.remove('redirect')
    // }
       this.$ls.set('referral',null)
      var referralParams=this.$route.query.referral
      if(referralParams!=null)
      {
        this.$router.replace({'query': null});
        //console.log(referralParams)
        // referralParams=referralParams.replaceAll('-A12-','/')
        // referralParams=referralParams.replaceAll('-B12-','\\')
        // //console.log(referralParams)
        if(referralParams!=null){
          var arr=referralParams.split('1-0');
          referralParams=arr[1].replace('diresu','')
        }
        // referralParams= this.CryptoJS.AES.decrypt(referralParams, "Think higher").toString(this.CryptoJS.enc.Utf8)
        this.$ls.set('referral',referralParams)
        //console.log(referralParams)
        //this.$root.$emit('bv::show::modal', 'login-Modal')
      }
      this.$ls.set('userSettings',null);
      var userSettings=this.$route.query.userSettings;
      if(userSettings!=null){
        this.$router.replace({'query': null});
        this.$ls.set('userSettings',userSettings);
      }
      if(this.$ls.get('tokenVal') == 'null' | this.popupPage =='resetPassword'){
        //this.$root.$emit('bv::show::modal', 'login-Modal')
        this.clickedQuestion=''
       }

         if(this.$route.query.moduleType)
         {
           this.moduleName=this.$route.query.moduleType
           //console.log(this.moduleName)
            this.$router.replace({'query': null});
         }
       
    }
     const user=this.$ls.get('user')
    this.userMessage.user_id=user.user_id
    this.userMessage.first_name=user.first_name
    this.userMessage.last_name=user.last_name
    this.userMessage.email=user.email_id
    this.userMessage.mobileNumber=user.toNumber
    //console.log(this.userMessage)
    //console.log(user)
    
    
  },
 
  
}

</script>
<style>
#promotionModel .btn{
  float: right;
    background: #fff;
    width: 25px;
    height: 25px;
    line-height: 5px;
    padding: 0px;
}
#promotionModel  .modal-body{
  padding: .5rem;
}
.messagePopup
{
    position: fixed!important;
    bottom: 20px;
    right: 20px;
    z-index: 99999999;
    background-color: #f23276!important;
    border: #f23276!important;
    border-radius: 50px!important;
    width: 60px;
    height: 60px;
    padding: 0px;
}
.messageText
{
  color: white !important;
    right: 90px;
    /* width: 130px; */
    /* height: 35px; */
    bottom: 30px;
    border-radius: 8px!important;
    /* font-family: Open Sans!important; */
    font-weight: 401!important;
    /* letter-spacing: 0.1rem; */
    position: fixed!important;;
    z-index: 99999!important;;
    background-color: #f23276!important;;
    border-color: #f23276!important;;
    padding: 2px 10px!important;
    margin: 0px!important;;
    font-size: 17px!important;
}
.messageText:before {
      content: "";
    position: absolute;
    left: 100%;
    top: 7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 12px solid #f23276;
    border-bottom: 7px solid transparent;
    }
.form-control:focus
{
  box-shadow: none!important;
}
.form-control
{
  height: calc(2.5rem + 0px)!important;
}
.questionList, .questiondesc 
{
  margin-top: 5px;
}
.questiondesc p
{
  font-size: 15px;
  padding: 10px 20px!important;
  text-align: justify;
  text-justify: inter-word;
}
.row
{
  margin:0px!important;
}

.vue-audio-recorder, .vue-video-recorder {
  margin-right: 16px;
}
.record-settings {
  display: flex;
  justify-content: center;
  margin-top: 7px;
}
/* .recorded-audio {
  margin: 0 auto;
  height: 200px;
  overflow: auto;
  border: thin solid #eee;
  background-color: #f7f7f7;
  padding: 10px 5px;
} */
.recorded-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .recorded-item .btn
  {
    margin-top: 16px;
  }
  .audio-container {
    display: flex;
    height: 40px!important;
    margin-right: 0px;
  }
ol{
list-style: none;
}
ol input[type="radio"], ol input[type="checkbox"]{
  margin-right: 5px;
}
input[type="radio"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 2px !important;
    background-clip: content-box;
    border: 2px solid #343a40;
    background-color: #e7e6e7;
    border-radius: 50%;
    margin-bottom: -2px;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked{
  background-color: #007bff;
}
input[type="checkbox"] {
  visibility: hidden;
}
ol span {
  cursor: pointer;
}
input[type="checkbox"] + span:before {
  border: 2px solid #343a40;
  content: "\00a0";
  display: inline-block;
  /* font: 16px/1em sans-serif; */
  font: 16px/1em 'Montserrat'!important;
  height: 16px;
  margin: 0 .25em 0 0;
  padding: 0;
  /* vertical-align: top; */
  width: 16px;
  border-radius: 3px;
  font-weight: 900;
}
input[type="checkbox"]:checked + span:before {
  background: #fff;
  color: #28a745;
  content: "\2713";
  text-align: center;
}
input[type="checkbox"]:checked + span:after {
  font-weight: bold;
}

input[type="checkbox"]:focus + span::before {
    outline: rgb(59, 153, 252) auto 5px;
}
.wrongAnswer
{
  color: red;
}
.wrongAnswer input[type="radio"]:checked {
  background-color: red;
}
.wrongAnswer input[type="checkbox"]:checked + span:before,.is-danger {
  color: red;
}
.rightAnswer
{
      color: #001848;
}
.rightAnswer input[type="radio"]:checked {
  background-color: green;
}
.wrongSelection,.is-danger
{
  border-color: red!important;
}

.prepare div, .prepare p, .prepare div p{
  display: inline;
  color:red!important;
  padding: 1px!important;
font-weight: 600;
};
.rightSection
{
  border-color: green!important;
}
.audio-container
{
width: 100%;
height: 80px;
}
audio
{
 width: 50%;
height: 50px !important;
background-color: #161616;
border-radius: 5px;
margin: auto;
}
.audio-container.left audio {
    width: 50%;
    height: 40px !important;
    /* background-color: #ffffff; */
    background: #f1f3f4!important;
    border-radius: 5px;
    margin: 0!important;
    filter: brightness;
    filter: invert(0%);
}
.textAreaSingleLine
{
resize: none;
width: 100%;
}
textarea
{
  font-size: 15px;
padding: 5px 10px;
border: 1px solid #ccc;
border-radius: 5px;
}
.ar-icon__lg
{
  width: 75px!important;
  height: 75px!important;
  line-height: 38px!important;
}
.ar-icon__lg.ar-icon
{
  fill: #24d1d3!important;
}
.ar-icon__lg.ar-icon.ar-icon--rec
{
  fill: white!important;
}
.ar-icon__sm {
    width: 65px!important;
    height: 65px!important;
    line-height: 20px!important;
    padding: 5px!important;
    top: 6px!important;
    right: -85px!important;
}
.ar-records, .ar-player, .ar-recorder__time-limit
{
display: none!important;
}
.ar {
    width: 100%!important;
    /* font-family: 'Montserrat'!important; */
    font-family: 'Montserrat'!important;
    border-radius: 0px!important;
    background-color: transparent!important;
    box-shadow: none!important;
    position: relative!important;
    box-sizing: unset!important;
}
.ar-recorder__duration
{
  width: 100%;
  text-align: center;
  /* padding-left: 75px; */
font-size: 21px!important;
font-weight: 500!important;
margin-top: 12px!important;
margin-bottom: 16px!important;
}
.questdesc
{
  border: 1px dashed rgb(153, 153, 153);
  padding: 20px;
  line-height: 1.8rem;
  position: relative;
  overflow-wrap: break-word;
  word-break: normal;
  background-color: rgb(248, 248, 249);
}
.questHeading
{
  font-weight: 600;
padding-bottom: 1rem;
border-bottom: 1px solid #e6e6e6;
margin-bottom: 3rem;
color: #6c6c6c;
}
.questiondesc.col-9 > h4{
color: #6c6c6c;
}
.displayInline
{
  display: inline-block;
  margin-top: 20px;
}
#login-Modal .modal-body
{
  padding: 2rem 1rem;
  background: #f0f0f0;
border-radius: 5px;
}
.questonhelp p, .questonhelp ul{
font-size: 15px!important
}
.questonhelp
{
  margin-top: 15px;
}
.questonhelp h4
{
    color: #f23276;
    font-weight: 600;
}
.questonhelp .fancy {
  line-height: 0.5;
  text-align: center;
}
.questonhelp .fancy span {
  display: inline-block;
  position: relative;  
}
.questonhelp .fancy span:before,
.questonhelp .fancy span:after {
 content: "";
    position: absolute;
    height: 5px;
    border-bottom: .18rem solid #f23276;
    border-top: .18rem solid #f23276;
    top: 5px;
    width: 40%;
}
.questonhelp .fancy span:before {
  right: 100%;
  margin-right: 15px;
}
.questonhelp .fancy span:after {
  left: 100%;
  margin-left: 15px;
}
.swal2-styled.swal2-confirm
{
  background-color:#125897 !important;
  border: 0px !important;
  color: #fff!important;
}
.swal2-styled.swal2-confirm:focus, .swal2-styled.swal2-confirm:focus-within
{ 
  box-shadow:none!important
}
/* .prevQuestion
{
  background: #1890fb !important;
  border: 0px !important;
  padding: 8px!important;
}
.prevQuestion:hover{
   background: #0081f1 !important;
}
.nextQuestion
{
  background: #1890fb !important;
  border: 0px !important;
  padding:8px!important;
}
.nextQuestion:hover{
   background: #0081f1 !important;
} */
.nextQuestion{
  margin-top:0px!important;
}
.prevQuestion{
  margin-top:1px!important;
}
.ar-icon__lg, .ar-icon__sm {
    width: 55px !important;
    height: 55px !important;
    line-height: 33px !important;
    box-shadow: 0 2px 5px 1px rgba(158, 158, 158, 0.5);
}
.hideRecord
{
  display: none!important;
}
.record-settings .ar-recorder__stop {
   display: none!important;
    position: absolute!important;
    top: 0px !important;
    right: 0px !important; 
}
.ar-recorder__stop.upFront{
    display: block!important;
    top: 0px !important;
    left: 0px;
    background: transparent;
    z-index: 99999;
}
.record-settings .ar-recorder__stop svg{
  display: none!important;
}
.showStop
{
  position: relative!important;
display: block!important;
}

p.recordMessage
{
width: 100%;
position: relative;
display: block;
text-align: center!important;
margin: 0px!important;
padding: 16px !important;
padding-bottom: 0px !important;
margin-top: 30px !important;
}
.ar-content
{
  padding-top: 0px!important;
}
.btn-warning {
    width: auto!important;;
}
.btn-info, .btn-warning, .btn-primary,
 .btn-danger, .prevQuestion, .nextQuestion, 
 .btn-outline-dark, .btn-success,
 .col-8.p-0 .input-group .form-control{
  font-size: .8rem!important;
  font-size: 1rem;
}
.btn-info, .btn-warning, .btn-primary,
 .btn-danger, .prevQuestion, .nextQuestion, 
 .btn-outline-dark, .btn-success{
   margin-top:2px;
 }
.questionMark
{
  padding-left: 0px !important;font-weight: bolder;color: #01162f;
}
.input-group-prepend {
padding: 10px 16px;
background: #e9ecef;
border:1px solid #e9ecef;
border-top-left-radius: 4px;
border-bottom-left-radius: 4px;
color: #495057;
font-weight: 800;
}
.col-8.p-0 .input-group .input-group-append .btn
{
  font-size: .8rem;
  font-size: 1rem;
padding: 0px 12px;
line-height: 14px;
height: 2.3rem;
}
.col-8.p-0 .input-group .input-group-prepend
{
  font-size: .8rem;
  font-size: 1rem!important;
    height: 2.3rem;
}
#header .navigation-row .dropdown-item:hover, .navigation-row .dropdown-item:focus {
    color: #fff!important;
}
.fixedHeight .b-overlay.position-absolute
{
  position:fixed!important;
  width:100%;
  height:100%;
}
.unselectable {
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
.badge-success, button, button.btn,div.btn, label.btn, .vue-js-switch .v-switch-label,
.nav-item a, .nav-item a:hover,.nav-item a:active,.nav-item a:focus,.nav-item a:visited,
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus
{
  color: #001848!important
}
.readPassage, .readPassage svg{
      color: #24d1d3!important;
}
.btn{
  font-weight: 600!important;
  border-width: 2px!important;
}
#console-textarea{
  border: 4px solid #ccc;
    background: #fff!important;
    white-space: pre-line;
    height: 300px;
    padding: 5px 10px;
    list-style: none;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    /* background-color: #a22b2b; */
    overflow: auto;
}
</style>
