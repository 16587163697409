import axios from 'axios'
import Vue from 'vue'
//import LocalStorageService from '../LocalStorageService'

export default {
    namespaced: true,
    state: {
        token: null,
        user: null,
        uniqueKey:null,
        packages:null,
        userPersonalInfo: null,
        countriesList: null,
        error: null,
        updateResponse: null,
        UpdateMessage: '',
        questionStatusResponse: null,
        practiceNumber: 0,
        dashboardResponse: null,
        stripe: null,
        simpleResponse:null
    },

    getters: {
        authenticated(state) {
            return { token: state.token, user: state.user,uniqueKey:state.uniqueKey };
        },
        user(state) {
            return state.user;
        },
        packages(state) {
            return state.packages;
        },
        getPersonalInfo(state) {
            return state.userPersonalInfo;
        },
        getCountriesList(state) {
            return state.countriesList;
        },
        getStrip(state) {
            return state.stripe;
        },
        getError(state) {
            return state.error;
        },
        UpdateResponse(state) {
            return state.updateResponse
        },
        UPDATEQuestionStatus(state) {
            return state.questionStatusResponse
        },
        UserMessages(state) {
            return state.UpdateMessage
        },
        getPracticeNumber(state) {
            return state.practiceNumber
        },
        getDashBoardResponse(state) {
            return state.dashboardResponse
        },
        getSimpleResponse(state) {
            return state.simpleResponse
        }

    },

    actions: {
        async login({ dispatch }, credentials) {
            const response = await axios.post('users/login', credentials)
            if (response != undefined) {
                console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data.message)
                } else {
                    dispatch('attempt', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async fetchUser({ dispatch }, data) {
            const response = await axios.post('users/getUserByUserName', data)
            if (response != undefined) {
                console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data.message)
                } else {
                    //debugger
                    dispatch('reSetattempt', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async stripActions({ dispatch }, data) {
            const response = await axios.post(data.url, data)
            if (response != undefined) {
                console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data.message)
                } else {
                    dispatch('setStripeData', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async fetchGraphData({ dispatch }, url) {
            const response = await axios.get(url)
            if (response != undefined) {
                console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data)
                    dispatch('setErrror', response.data)
                } else {
                    dispatch('passQuestionPractice', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async fetchSimpleResponse({ dispatch }, url) {
            const response = await axios.get(url)
            if (response != undefined) {
                console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data)
                    
                    dispatch('setErrror', response.data)
                } else {
                   // dispatch('passQuestionPractice', response.data)
                    dispatch('setErrror', '')
                }
                dispatch('passSimpleResponse', response.data)
            }
        },
        async updateUserType({ dispatch }, data) {
            const response = await axios.post(data.url, data)
            if (response != undefined) {
                //console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data.message)
                } else {
                    dispatch('passUser', response)
                    dispatch('setErrror', '')
                }
            }
        },
        async updateQuestionStatus({ dispatch }, data) {
            const response = await axios.post(data.url, data)
            if (response != undefined) {
                //console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data)
                } else {
                    dispatch('passQuestionStatus', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async saveDashBoardData({ dispatch }, data) {
            console.log(data)
            const response = await axios.post(data.url, data)
            if (response != undefined) {
                //console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data)
                } else {
                    dispatch('passDashboard', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async saveUserPersonalInfo({ dispatch }, data) {
            console.log(data)
            const response = await axios.post(data.url, data)
            if (response != undefined) {
                //console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data)
                } else {
                    dispatch('passUserPersonalInfo', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async fetchCountryList({ dispatch }, url) {
            console.log(url)
            const response = await axios.get(url)
                //console.log(response)
            if (response != undefined) {
                //console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data)
                } else {
                    dispatch('passCountryList', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async fetchUserPersonalInfo({ dispatch }, url) {
            //console.log(url)
            const response = await axios.get(url)
            console.log(response)
            if (response != undefined) {
                //console.log(response)
                if (response.data.success == 0) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data)
                } else {
                    dispatch('passUserPersonalInfo', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async fetchUserMiniInfo({ dispatch }, url) {
            //console.log(url)
            const response = await axios.get(url)
            console.log(response)
            if (response != undefined) {
                //console.log(response)
                if (response.data.success == 0) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data)
                } else {
                    dispatch('passUserPersonalInfo', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async updateUserRecord({ dispatch }, data) {
            const response = await axios.post(data.url, data)
            if (response != undefined) {
                //console.log(response)
                if (response.data.success != 1) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.message)
                } else {
                    dispatch('passUserMessage', response.message)
                    dispatch('setErrror', '')
                }
            }
        },
        async passUser(context, data) {
            try {
                //console.log(data)
                context.commit('SET_UPDATE_RESPONSE', data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async passQuestionStatus(context, data) {
            try {
                //console.log(data)
                context.commit('SET_UPDATE_Question_Status', data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async passDashboard(context, data) {
            try {
                //console.log(data)
                context.commit('SET_DASHBOARD_RESPONSE', data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async passUserPersonalInfo(context, data) {
            try {
                //console.log(data)
                context.commit('SET_USER_PERSONAL_INFO', data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async passCountryList(context, data) {
            try {
                //console.log(data)
                context.commit('SET_COUNTRY_LIST', data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async passQuestionPractice(context, data) {
            try {
                //console.log(data)
                context.commit('SET_USER_PRACTICE_QUESTION', data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async passSimpleResponse(context, data) {
            try {
                //console.log(data)
                context.commit('SET_SIMPLE_RESPONSE', data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async passUserMessage(context, message) {
            try {
                //console.log(data)
                context.commit('SET_USER_MESSAGE', message)
            } catch (e) {
                //console.log('failed')
            }
        },
        async loginSocialMedia({ dispatch }, data) {
            const response = await axios.post('users/getUserByEmailID', data) 
            if (response != undefined) {
                console.log('Inside social Media signin')
                console.log(response)
                Vue.ls.set('NewUser',response.data.success);
                if (response.data.success == 0) {
                    console.log(response.data.message)
                    dispatch('setErrror', response.data.message)
                } else {
                    dispatch('attempt', response.data)
                    dispatch('attempt', response.data)
                    dispatch('setErrror', '')
                }
            }
        },
        async attempt(context, data) {
            context.commit('SET_TOKEN', data.token);
            context.commit('SET_UNIQUEKEY', data.uniqueKey);
            //context.commit('SET_USER', data.user);
            //console.log(data)
            //LocalStorageService.setToken(data.token);
            var token = data.token
            debugger
            //Vue.$browserStore.clear()
            //Vue.$browserStore.setItem('tokenVal', token)
            Vue.ls.set('tokenVal', token)
            Vue.ls.set('uniqueKey', data.uniqueKey)
                //console.log('attempt data')
            console.log(data)
            try {
                //debugger
                console.log('IN AUTH')
                console.log(data.user)
                var us = {
                    user_id: data.user.user_id,
                    first_name: data.user.first_name,
                    last_name: data.user.last_name,
                    email_id: data.user.email_id,
                    is_admin: data.user.is_admin,
                    user_type: (data.user.user_type == null) ? 'Free' : data.user.user_type, //is_admin,user_type
                    writing_Counter: data.user.writing_result_counter,
                    speaking_Counter: data.user.speaking_result_counter,
                    speaking_Date: data.user.speaking_result_date,
                    stripe_subscription: data.user.stripe_subscription,
                    stripe_customerId: data.user.stripe_customerId,
                    payment_date: data.user.payment_date,
                    pteExpiry: data.user.pteExpiry,
                    coaching: (data.user.coaching == null) ? 'No' : data.user.coaching,
                    coaching_stripe_SessionId: data.user.coaching_stripe_SessionId,
                    coaching_stripe_subscription: data.user.coaching_stripe_subscription,
                    coaching_payment_date: data.user.coaching_payment_date,
                    coaching_stripe_membership: data.user.coaching_stripe_membership,
                    coaching_payment_cancel: data.user.coaching_payment_cancel,
                    coaching_amount: data.user.coaching_amount,
                    coaching_cancel_membership: data.user.coaching_cancel_membership,
                    coaching_stripe_customerId: data.user.coaching_stripe_customerId,
                    coachingExpiry: data.user.coachingExpiry,
                    coaching_payment_type: data.user.coaching_payment_type,
                    pte_payment_type: data.user.pte_payment_type,
                    extra_mock_allow: data.user.extra_mock_allow,
                    extra_mockTest: data.user.extra_mockTest,
                    toNumber:data.user.toNumber,
                    reference_user_id:data.user.reference_user_id,
                    ref_user_cat:data.user.ref_user_cat,
                    ref_user_cat_id:data.user.ref_user_cat_id,
                    category_id:data.user.category_id,
                    premium_video_allowed:data.user.premium_video_allowed,
                    uniqueKey:data.uniqueKey,
                    Product_User:data.user.Product_User,
                    Product_Expiry:data.user.Product_Expiry,
                    Product_Package_Name:data.user.Product_Package_Name
                };
                console.log(us);
                Vue.ls.set('user',us);
                context.commit('SET_USER', us)
                    //LocalStorageService.userData(us); 
               
            } catch (e) {
                //console.log('failed'+ e)
            }
        },
        async reSetattempt(context, dataObject) {
            //context.commit('SET_TOKEN', data.token);
            //context.commit('SET_USER', data.user);
            //console.log(data)
            //LocalStorageService.setToken(data.token);
            // var token = data.token
            // Vue.ls.set('tokenVal', token)
            //console.log('attempt data')
            console.log(dataObject)
            var data = dataObject.data;
            try {
                //debugger
                var us = {
                    user_id: data.user_id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email_id: data.email_id,
                    is_admin: data.is_admin,
                    user_type: data.user_type, //is_admin,user_type
                    writing_Counter: data.writing_result_counter,
                    speaking_Counter: data.speaking_result_counter,
                    speaking_Date: data.speaking_result_date,
                    stripe_subscription: data.stripe_subscription,
                    stripe_customerId: data.stripe_customerId,
                    payment_date: data.payment_date,
                    pteExpiry: data.pteExpiry,
                    coaching: data.coaching,
                    coaching_stripe_SessionId: data.coaching_stripe_SessionId,
                    coaching_stripe_subscription: data.coaching_stripe_subscription,
                    coaching_payment_date: data.coaching_payment_date,
                    coaching_stripe_membership: data.coaching_stripe_membership,
                    coaching_payment_cancel: data.coaching_payment_cancel,
                    coaching_amount: data.coaching_amount,
                    coaching_cancel_membership: data.coaching_cancel_membership,
                    coaching_stripe_customerId: data.coaching_stripe_customerId,
                    coachingExpiry: data.coachingExpiry,
                    coaching_payment_type: data.coaching_payment_type,
                    pte_payment_type: data.pte_payment_type,
                    extra_mock_allow: data.extra_mock_allow,
                    extra_mockTest: data.extra_mockTest,
                    toNumber:data.toNumber,
                    reference_user_id:data.reference_user_id,
                    ref_user_cat:data.ref_user_cat,
                    ref_user_cat_id:data.ref_user_cat_id,
                    category_id:data.category_id,
                    premium_video_allowed:data.premium_video_allowed,
                    uniqueKey:data.uniqueKey,
                    Product_User:data.Product_User,
                    Product_Expiry:data.Product_Expiry,
                    Product_Package_Name:data.Product_Package_Name
                };
                console.log(us);
                Vue.ls.set('user', us)
                context.commit('SET_USER', us)
                console.log(us)
                    //LocalStorageService.userData(us); 
            } catch (e) {
                console.log('failed' + e)
            }
        },
        async getUserById(context, id) {
            const response = await axios.get('users/id=' + id)
                //console.log(response.data)
            try {
                context.commit('SET_USER', response.data.user)
            } catch (e) {
                //console.log('failed')
            }
        },
        async getPackagesById(context, id) {
            const response = await axios.get('users/getPackageCategoryById/id=' + id)
                console.log(response.data)
            try {
                context.commit('SET_PACKAGES', response.data)
            } catch (e) {
                //console.log('failed')
            }
        },
        async setErrror(context, err) {
            context.commit('SET_ERROR', err)
        },
        async setStripeData(context, data) {
            context.commit('SET_STRIPE', data)
        },
        // async setUserPersonalInfo(context, data) {
        //     context.commit('SET_USER_PERSONAL_INFO', data)
        // },
        async setCountryList(context, data) {
            context.commit('SET_COUNTRY_LIST', data)
        },
        async removeSession(context) {
            // LocalStorageService.setToken(null);
            // LocalStorageService.userData(null);
            // LocalStorageService.setCurrentQuestion(null)
            Vue.ls.clear()
            context.commit('REMOVE_STATE', null)
        }
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_UNIQUEKEY(state, uniqueKey) {
            state.uniqueKey = uniqueKey
        },
        SET_USER(state, data) {
            state.user = data
        },
        SET_PACKAGES(state, data) {
            state.packages = data
        },
        SET_STRIPE(state, data) {
            state.stripe = data
        },
        SET_ERROR(state, data) {
            state.error = data
        },
        REMOVE_STATE(state, data) {
            console.log(data)
            console.log(state)
            state = {
                token: null,
                user: null,
                error: null,
            }
        },
        SET_UPDATE_RESPONSE(state, data) {
            state.UpdateResponse = data
        },
        SET_UPDATE_Question_Status(state, data) {
            state.questionStatusResponse = data
        },
        SET_DASHBOARD_RESPONSE(state, data) {
            state.dashboardResponse = data
        },
        SET_USER_MESSAGE(state, message) {
            state.UpdateMessage = message
        },
        SET_USER_PRACTICE_QUESTION(state, data) {
            state.practiceNumber = data
        },
        SET_SIMPLE_RESPONSE(state, data) {
            state.simpleResponse = data
        },
        SET_USER_PERSONAL_INFO(state, data) {
            state.userPersonalInfo = data
        },
        SET_COUNTRY_LIST(state, data) {
            state.countriesList = data
        }
    }

}